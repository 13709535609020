<script lang="tsx">
import { defineComponentBaseUiInput } from '@core/app/components/base/ui/BaseUiInput.vue'
import type { SizeProp } from '@core/types/components'
import { IconCrossCircleSolid } from '#components'


export type BaseUiInputColors = 'white' | 'black'
export type BaseUiInputVariants = 'solid' | 'inline'
export type BaseUiInputSizes = SizeProp | 'none'

export default defineComponentBaseUiInput<BaseUiInputColors, BaseUiInputVariants, BaseUiInputSizes>({
    props: {
        size: {
            default: 'md',
        },
        color: {
            default: 'black',
        },
        variant: {
            default: 'solid',
        },
    },
    slots: {
        clear: () => <IconCrossCircleSolid size="md" class="text-primary-500" />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiInput" as *;

@include input {
    border-radius: $mon-border-radius;

    font-weight: 300;

    @include set-item-gap(0.625rem);
}

@include textarea {
    border-radius: $mon-border-radius-extra-small;
}

@include placeholder {
    @include mon-text-small;
    @include mon-font-light;
}

@include side-content {
    color: $mon-c-black;
}

@include clear-button {
    border-radius: 100vmax;
    padding: 0.25rem;
    margin: -0.75rem -0.25rem;
}

.icon {
    &.v-enter-active,
    &.v-leave-active {
        transition: transform 75ms $mon-timing;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: scale(0.85);
    }
}

@include input--variant('inline') {
    background: transparent;
    border-radius: initial;
    border-bottom: 1px dashed $mon-c-basic-200;

    &:focus-within {
        border-bottom: 1px solid $mon-c-black;
    }

    @include placeholder {
        color: $mon-c-black;
    }

    @include spin-buttons {
        appearance: none;
        margin: 0;
    }
}

@include input--color('white') {
    @include input--variant('solid', '&') {
        background-color: $mon-c-white;
        color: $mon-c-black;

        &:focus-within {
            outline: 1px solid $mon-c-primary-500;
        }

        @include placeholder {
            color: $mon-c-black;
        }
    }
}

@include input--color('black') {
    @include input--variant('solid', '&') {
        background-color: $mon-c-primary-100;
        color: $mon-c-black;

        &:focus-within {
            outline: 1px solid $mon-c-primary-500;
        }

        @include placeholder {
            color: $mon-c-black;
        }
    }
}

@include input--error {
    color: $mon-c-danger-500;
    outline: 1px solid $mon-c-danger-500;
    background-color: $mon-c-white;

    &:focus-within {
        outline: 2px solid $mon-c-danger-500;
    }
}

// --------------------------------------------------------------------
// Input sizes

@include input--size('md') {
    @include set-padding(0.625rem, 1.25rem);
}

@include input--size--textarea('md') {
    @include set-padding(1.25rem, 1.25rem);
}

@include input--size('lg') {
    @include set-padding(0.625rem, 1.5rem);
}

@include input--size('none') {
    @include set-padding(0, 0);
}

</style>
